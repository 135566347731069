var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container",class:{ 'px-0': _vm.$vuetify.breakpoint.smAndUp }},[_c('div',{staticClass:"footer__inner"},[_c('v-row',{staticClass:"footer__up mt-12",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"footer__up-left col-lg-9 col-md-9 col-sm-9 col-12",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"footer__logo"},[_c('img',{attrs:{"width":"90","height":"58px","src":_vm.footerMenus[5]?.logo,"alt":"Logo-Tumar"}})]),_c('div',{staticClass:"footer__text",domProps:{"innerHTML":_vm._s(_vm.footerMenus.find((item) => item.block === 1)?.content)}})]),_c('div',{staticClass:"footer__social"},[_c('div',{staticClass:"mr-3"},[_c('a',{attrs:{"href":_vm.socials[0].url,"target":"_blank"}},[_c('img',{staticClass:"social_img",attrs:{"src":"https://admin.tumar.com//storage/filemanager/Footer/facebook_.png","alt":""}})])]),_c('div',{staticClass:"mr-3"},[_c('a',{attrs:{"href":_vm.socials[3].url,"target":"_blank"}},[_c('img',{staticClass:"social_img",attrs:{"src":"https://admin.tumar.com//storage/filemanager/Footer/pinterst.png","alt":""}})])]),_c('div',{staticClass:"mr-3"},[_c('a',{attrs:{"href":_vm.socials[1].url,"target":"_blank"}},[_c('img',{staticClass:"social_img",attrs:{"src":"https://admin.tumar.com//storage/filemanager/Footer/instagram.png","alt":""}})])]),_c('div',{staticClass:"mr-3"},[_c('a',{attrs:{"href":_vm.socials[2].url,"target":"_blank"}},[_c('img',{staticClass:"social_img",attrs:{"src":"https://admin.tumar.com//storage/filemanager/Footer/youtube.png","alt":""}})])])])],1),_c('div',{staticClass:"footer__down"},[_c('div',{staticClass:"col-12 footer__menu pa-0"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticStyle:{"margin":"auto"},attrs:{"no-gutters":""}},_vm._l((_vm.footerMenus
                  .filter(
                    (item) =>
                      item.block >= 2 && item.block <= 6 && item.block !== 5
                  )
                  .sort((a, b) => a.block - b.block)),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"6","md":"3"}},[_c('span',{staticClass:"footer__item-title mb-4"},[_vm._v(" "+_vm._s(item?.title)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(item?.content)}})])}),1)],1)],1),_vm._m(0)])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 footer__copyright"},[_c('p',{staticClass:"copyright"},[_vm._v("2021 Tumar Art Group. All rights reserved.")])])
}]

export { render, staticRenderFns }