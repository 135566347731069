<template>
  <ul v-if="array.length" class="nav__link-dropdown-menu mb-0 px-0">
    <li v-for="item in array" :key="item.id" class="nav__link-dropdown pa-0">
      <v-hover v-slot="{ hover }">
        <div style="padding: 10px;">
          <router-link class="nav__link-dropdown-link" :to="`${item.url}`">
            {{ isCategory ? item.title.ru : item.title }}
          </router-link>
          <menu-recursion
            v-if="hover"
            :style="{ top: '0', left: left ? '-100%' : '100%' }"
            class="pa-0"
            :array="item.childrens"
            :left="left"
          />
        </div>
      </v-hover>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MenuRecursion',
  props: {
    array: {
      type: Array,
      default: () => [],
    },
    isCategory: {
      type: Boolean,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
